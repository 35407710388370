<!--
 * @Description: 热门商圈
 * @Author: 琢磨先生
 * @Date: 2022-06-10 14:25:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-13 11:14:37
-->

<template>
  <el-card class="box">
    <el-form ref="query" :inline="true">
      <el-form-item label="">
        <el-select v-model="area_id" placeholder="" @change="areaChange">
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-button type="primary" size="small" round icon="plus" @click="onEdit()"
      >新增</el-button
    >
  </el-card>
  <el-card class="box" v-loading="loading">
    <el-table :data="dataList" border>
      <el-table-column
        label="序号"
        prop="sequence"
        width="100"
      ></el-table-column>
      <el-table-column label="商圈" prop="location.name"></el-table-column>
      <el-table-column label="简称" prop="alias"></el-table-column>
      <el-table-column label="房源数" prop="total_count" width="200"></el-table-column>
      <!-- <el-table-column
        label="自动抓取"
        prop="value"
        width="140"
      >
        <template #default="scope">
          {{scope.row.is_auto?'是':''}}
        </template>
      </el-table-column> -->
      <el-table-column width="180" label="操作" fixed="right">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            v-if="!scope.row.is_super"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button size="small" type="danger" link
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog
    :title="title"
    v-model="dialogVisible"
    width="1000px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading_dtl"
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="城市" prop="area_id">
        <el-select
          v-model="form.area_id"
          placeholder=""
          :disabled="!!form.id"
          @change="loadBusinessCircle"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in areaList"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商圈" prop="location_id">
        <el-select
          v-model="form.location_id"
          placeholder=""
          :disabled="!!form.id"
          @change="locationChange"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in circleList"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="简称" prop="is_auto">
        <el-col :span="10">
          <el-input v-model="form.alias" placeholder=""></el-input>
        </el-col>
        <div class="help-block">简称，存在时替换商圈名称显示</div>
      </el-form-item>

      <!-- <el-form-item label="自动抓取" prop="is_auto">
        <el-switch v-model="form.is_auto"></el-switch>
      </el-form-item> -->

      <el-form-item label="序号(正序)" prop="sequence">
        <el-col :span="10">
          <el-input v-model="form.sequence"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="附近房源">
        <el-table
          :data="nearby_houses"
          border
          stripe
          ref="refTable"
          height="400"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column width="120">
            <template #default="scope">
              <el-image
                :src="scope.row.logo_url"
                style="width: 80px; height: 80px"
                fit="cover"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column
            label="距离(km)"
            prop="km"
            width="100"
          ></el-table-column>
          <el-table-column
            label="状态"
            prop="status_text"
            width="100"
          ></el-table-column>
          <el-table-column label="名称" title="name">
            <template #header>
              <el-radio-group v-model="km" @change="kmChange">
                <el-radio :label="2">2km</el-radio>
                <el-radio :label="3">3km</el-radio>
                <el-radio :label="5">5km</el-radio>
                <el-radio :label="7">7km</el-radio>
              </el-radio-group>
            </template>
            <template #default="scope">
              <div>名称：{{ scope.row.name }}</div>
              <div>内部：{{ scope.row.private_name }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div>
          已选择房源：{{selection_houses.length}}/{{nearby_houses.length}}条
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确 定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      //加载详情
      loading_dtl: false,
      saving: false,
      dialogVisible: false,
      title: "",
      area_id: "",
      //城市
      areaList: [],
      //商圈列表
      circleList: [],
      //数据列表
      dataList: [],
      form: {},
      rules: {
        area_id: [{ required: true, message: "请输入", trigger: "blur" }],
        location_id: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      //范围km
      km: 3,
      //商圈附近的房源
      nearby_houses: [],
      //选中的房源
      selection_houses: [],
    };
  },
  created() {
    this.loadArea();
  },
  methods: {
    /**
     * 获取城市
     */
    loadArea() {
      this.$http.get("admin/v1/common/search_area").then((res) => {
        if (res.code == 0) {
          this.areaList = res.data;
          if (this.areaList.length > 0) {
            this.area_id = this.areaList[0].id;
            this.loadData();
          }
        }
      });
    },

    /**
     * 获取城市所有的商圈
     */
    loadBusinessCircle() {
      this.$http
        .get("admin/v1/common/businiess-circle?areaId=" + this.form.area_id)
        .then((res) => {
          if (res.code == 0) {
            this.circleList = res.data;
          }
        });
    },

    /**
     * 加载板块数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("admin/v1/hotcircle?areaId=" + this.area_id ?? "")
        .then((res) => {
          if (res.code == 0) {
            this.dataList = res.data;
          }
          this.loading = false;
        });
    },

    /**
     * 城市更改选择
     */
    areaChange() {
      this.loadData();
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.circleList = [];
      this.nearby_houses = [];
      this.title = item ? "修改热门商圈" : "新增热门商圈";
      this.form = item ? Object.assign({}, item) : {};
      if (this.form.area_id) {
        this.loadBusinessCircle();
      }
      if (this.form.id) {
        this.loadDtl();
      }
      this.dialogVisible = true;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.saving = true;
      this.form.values = [];
      if (this.selection_houses && this.selection_houses.length > 0) {
        this.form.house_ids = this.selection_houses.map((x) => x.id);
      }
      this.$http.post("admin/v1/hotcircle/edit", this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
        this.saving = false;
        this.dialogVisible = false;
      });
    },

    /**
     * 删除
     */
    onDelete(item) {
      this.$http.get("admin/v1/hotcircle/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
      });
    },
    /**
     * 上传回调
     */
    uploadChange(url) {
      this.form.pic_url = url;
    },

    /**
     * 获取详情
     */
    loadDtl() {
      this.loading_dtl = true;
      this.$http
        .get("admin/v1/HotCircle/dtl?id=" + this.form.id)
        .then((res) => {
          if (res.code == 0) {
            this.form.values = res.data.values;
            this.getHouse();
          }
        });
    },

    /**
     * 获取商圈附近范围的房源
     */
    getHouse() {
      this.loading_dtl = true;
      this.$http
        .get(
          `admin/v1/hotCircle/houses?locationId=${this.form.location_id}&km=${this.km}`
        )
        .then((res) => {
          if (res.code == 0) {
            if (res.data) {
              this.nearby_houses = res.data;
              res.data.forEach((item) => {
                if (
                  this.form.values &&
                  this.form.values.find((o) => o.house_id == item.id)
                ) {
                  this.$nextTick(() => {
                    this.$refs.refTable.toggleRowSelection(item, true);
                  });
                }
              });
            }
          }
          this.loading_dtl = false;
        });
    },
    /**
     * 选择变化
     */
    selectionChange(values) {
      this.selection_houses = values;
    },
    /**
     * 商圈选择更改
     */
    locationChange() {
      this.getHouse();
    },
    /**
     * 距离选择更改
     */
    kmChange() {
      this.getHouse();
    },
  },
};
</script>
<style >
</style>